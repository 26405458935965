@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* daisyui: fix a strange bug with collapse within dropdown */
.dropdown-content .collapse,
.dropdown-content .collapse * {
  visibility: inherit !important;
  opacity: inherit !important;
}

/*/////////////////////////////////////////////////LANDING PAGE/*/

#landing {
  padding-top: 3em;
  color: #f9f9f9;
}

#landing .bg-floating {
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
}

#landing .relative {
  position: relative;
}

#landing .padding-top {
  padding-top: 32em;
}

#landing .padding-bottom {
  padding-bottom: 20em;
}

#landing .shadow-top {
  box-shadow: -1em 2em 3em -1.5em rgba(50, 50, 93, 0.5),
    -1em 2em 3em -2em rgba(0, 0, 0, 0.6);
}

#landing .shadow-bottom {
  box-shadow: -1em 2em 3em 1.5em rgba(50, 50, 93, 0.5),
    -1em 2em 3em 2em rgba(0, 0, 0, 0.6);
}

#landing .max-width-60 {
  max-width: 60%;
}

#landing .txt-right {
  text-align: right;
}

#landing .block {
  position: absolute;
  width: 100%;
  height: 40em;
  transform: skewY(-8deg);
  transform-origin: 0;
  background-color: #163b82;
  overflow: hidden;
}

#landing .top-0 {
  top: 0;
}

#landing .bottom-0 {
  bottom: 0;
}

#landing .block span {
  position: absolute;
  width: 30vw;
  height: 6em;
}

#landing .grad1 span {
  background: linear-gradient(90deg,
      rgba(21, 21, 21, 0) 0%,
      rgba(255, 255, 255, 0.2) 100%);
}

#landing .grad2 span {
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(21, 21, 21, 0) 100%);
}

#landing .img {
  position: absolute;
  top: 25em;
  right: 0;
  transform: rotateX(45deg) rotateY(0deg) rotateZ(30deg);
  opacity: 1;
  animation: img 1s both;
  animation-delay: 0.6s;
  z-index: 10;
}

#landing .img svg {
  width: 35em;
  height: auto;
}

@keyframes img {
  0% {
    transform: rotateX(45deg) rotateY(0deg) rotateZ(60deg);
    opacity: 0;
  }
}

#landing .txt {
  letter-spacing: -0.01em;
  color: #f9f9f9;
  padding: 5em 1em 2em 1em;
}

#landing .txt h1 {
  font-size: 2em;
  line-height: 1em;
  font-weight: normal;
  margin-bottom: 1em;
  animation-delay: 0.2s;
}

#landing .txt p {
  line-height: 1.3333333333em;
  font-size: 1.5em;
  font-weight: lighter;
  animation-delay: 0.8s;
}

#landing .txt div {
  animation-delay: 1s;
}

#landing .tags {
  position: relative;
  z-index: 20;
}

#landing .txt .tags span {
  display: inline-block;
  margin-top: 1em;
  margin-right: 1em;
  font-size: 1em;
  font-weight: lighter;
  line-height: 2em;
  color: orange;
}

#landing .button {
  vertical-align: middle;
  padding: 0 0.6em;
  box-shadow: 0 0 0.15em orange;
  border-radius: 0.5em;
}

#landing .txt .contact {
  margin-top: 1em;
}

#landing .txt .contact a {
  display: inline-block;
  margin-top: 1em;
  margin-right: 1em;
  line-height: 2em;
  font-size: 1em;
  text-decoration: none;
  color: orange;
}

#landing .txt .contact a:hover {
  background-color: orange;
  color: #151515;
}

#landing .txt>* {
  opacity: 1;
  animation: txt 1s both;
}

@keyframes txt {
  0% {
    opacity: 0;
  }
}

#landing .blueprint {
  background-image: linear-gradient(rgba(255, 255, 255, 0.2) 0.5px,
      transparent 0.5px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0.5px, transparent 0.5px);
  background-size: 1em 1em;
  background-position: center center;
}

#landing svg .st1 {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: st1 2s ease-in-out forwards;
  animation-delay: 1.8s;
}

@keyframes st1 {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

#landing svg .st2 {
  stroke-dasharray: 3000;
  fill-opacity: 0;
  stroke-dashoffset: 0;
  fill-opacity: 1;
  animation: st2 2s ease-in-out both;
  animation-delay: 0.6s;
}

@keyframes st2 {
  0% {
    stroke-dashoffset: 3000;
    fill-opacity: 0;
  }
}

#landing svg .st3 {
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: st3 2s ease-in-out forwards;
  animation-delay: 1.5s;
}

@keyframes st3 {
  0% {
    stroke-dashoffset: 2000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

#landing svg .st4 {
  opacity: 1;
  animation: st4 2s ease-in-out both;
  animation-delay: 1.8s;
}

@keyframes st4 {
  0% {
    opacity: 0;
  }
}

#landing .reverse {
  flex-direction: row-reverse;
}

#landing .container {
  display: flex;
  flex-wrap: wrap;
}

#landing .item {
  position: relative;
  width: 50%;
  min-height: 24em;
}

#landing canvas {
  opacity: 1;
  animation: appear 1s both;
  animation-delay: 1.2s;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

@keyframes appear {
  0% {
    opacity: 0;
  }
}

#landing .copyright {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.5em 1.5em;
  font-size: 1em;
  font-weight: lighter;
}

@media screen and (max-width: 32rem) {
  #landing .max-width-60 {
    max-width: 100%;
  }

  #landing .item {
    width: 100%;
  }
}